<template>
  <div id="app1">
    <div class="header PT_5" @click="goBack">
      <van-icon size="20" name="arrow-left" />
      <span>我的企业</span>
    </div>
    <div class="box1"> 
      <div class="searchBtn">
        <van-icon size="30" name="search" />
         <van-field  @keyup.enter.native="getList"  v-model="company"  placeholder="输入企业信息"
          />
      </div>
    </div>
    <div class="companyList" v-if="list.length > 0">
        <div class="companyItem MB_5" @click="goDetail(item)" v-for="(item,index) in list" :key="index">
          <div class="companyTop">
            <div class="MR_1">
              <img style="width:4rem" src="../assets/nologo@2x.png" alt="">
            </div>
            <div>
              <div class="fontS_20 font_weight_bold">{{item.company}}</div>
              <div class="companyScore MT_1">
                <div>企业力度分：{{item.sum_score == '暂无' ? item.sum_score  : Number(item.sum_score).toFixed(0)}}</div>
                <div class="ML_5">企业人数 {{item.number}}</div>
              </div>
            </div>
          </div>
          <div class="companyModel">
            <div class="companyModel1">
              <img  src="../assets/sanren-icon@2x.png" alt="">
              <span>企业人力资源服务</span>
              <span style="margin-left:0.7rem;color: #96A6C4;">服务未激活</span>
            </div>
            <div class="companyModel1 MT_1" >
              <img  src="../assets/zonghe-icon@2x.png" alt="">
              <span>企业季度综合统计服务</span>
              <span style="margin-left:0.7rem;color: #96A6C4;">服务已激活</span>
            </div>
          </div>
          <div class="companyBottom MT_3">
            <div>
              <span>查看企业季度报告</span>
              <!-- <img src="../assets/gengduo-icon@2x.png" alt=""> -->
              <van-icon size="15" color="#3943BC" name="arrow" />
            </div>
            <div style="color: #6F81A3;"  v-if="item.day != '暂无'">{{item.day}}天前更新</div>
            <div style="color: #6F81A3;" v-else > </div>
          </div>
        </div>
    </div>
    <van-empty v-else style="background:#FFF" description="暂无公司" />
  </div>
</template>
<script>
import {mapState, mapActions } from 'vuex'
export default {
  data(){
    return {
      list:[],
      company:""
    }
  },
  computed: {
    ...mapState({
      userInfo:  (state) => state.userInfo,
    }),
  },
  mounted(){
    this.getList()
  },
  methods:{
    ...mapActions(["bindList","getScore"]),
    goDetail(item){
      this.$router.push({path:"/companyInfo",query:{
        company:item.company,
        uniformCreditCode:item.enterprise_tax_number,
      }})
    },
    goBack(){
      this.$router.go(-1)
    },
    getList(){
      this.bindList({
        reqType: "login", //请求类型
        id: this.userInfo.id, //企业信息id
        pageNo: 1,
        limit: 1000,
        company: this.company //公司
      }).then((res)=>{
        let list1 = res.data.companyList.map((item)=>{
          if(item.add_time != "暂无"){
           let a =  item.add_time.split(" ")[0]
            let date = new Date()
            var year = date.getFullYear(); 
            var month = date.getMonth() + 1;
            var day = date.getDate();
            month = (month > 9) ? month : ("0" + month);
            day = (day < 10) ? ("0" + day) : day;
            var today = year + "-" + month + "-" + day
            let b = this.getDay(a,today)
            console.log(b)
            item.day = b
          } else {
            item.day = "暂无"
          }
           
          if(item.sum_score != "暂无"){
            item.sum_score = Number(item.sum_score).toFixed(1)
          }
          return item
        })
        console.log(list1)
        this.list = list1
         
      })
    },
    toTimeStamp(dateString){
      // dateString例如:'2022-03-05'
      // 例如返回:1646611200000
      return new Date(dateString) - 0
    },
    getDay(date1,date2){
       
      // date1例如:'2022-03-05',date2例如:'2022-03-06'
      const date1_timeStamp = this.toTimeStamp(date1)
      const date2_timeStamp = this.toTimeStamp(date2)
      let max = '', min = ''
      if(date1_timeStamp>date2_timeStamp){
        max = date1_timeStamp
        min = date2_timeStamp
      }else{
        max = date2_timeStamp
        min = date1_timeStamp
      }
    // 例如返回:'1'
    return (max-min)/(24*60*60*1000)
    },
  }
}
</script>
<style scoped>
#app1{
  background-color: #F7F7F7;
  height:100vh;
}
.box1{
  padding:0.5rem;
  background: #fff;
}
.van-cell{
  background-color: transparent;
}
</style>